.loader {
  justify-content: center;
  display: flex;
}

@media screen and (min-width: 1200px) {
  .alertList {
    display: block;
    padding: 2rem 3rem;
  }

}
