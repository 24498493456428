.locationMenu {
  margin: 1rem 0 2.5rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .menuItemContainer {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--location-menu-font-color);
    text-transform: capitalize;
    cursor: pointer;

    &.selected {
      padding: 0.5rem 2rem;
      color: var(--secondary-background-color);
      background-color: var(--primary-font-color);
      border-radius: 1.5rem;
    }

    .menuItem {
      position: relative;

      &.hasAlerts::before {
        content: "";
        position: absolute;
        top: 0;
        right: -0.8rem;
        width: 0.45em;
        height: 0.45em;
        background-color: var(--color-red);
        border-radius: 50%;
      }
    }
  }
}

:global(.isDesktop) {
  .locationMenu {
    margin: 0;

    .menuItemContainer {
      margin-right: 3.5rem;
      font-size: 1.8rem;

      &.selected {
        margin-right: 2rem;
      }
    }
  }
}