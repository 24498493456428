@media screen and (min-width: 1200px){
  .onlyMobile {
    display: none;
  }
  .storageTab {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .overview {
      width: 100%;
      margin-bottom: 3rem;
    }

    .chart {
      width: 48%;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
}

.storageTab {
  .overview,
  .chart:not(:last-child) {
    margin-bottom: 1rem;
  }
}
