
.label {
  width: 100%;
  font-size: 1.6rem;
  margin-bottom: 5px;
  color: var(--primary-font-color);
}
.dropdown {
  width: 100%;
  height: 40px;
  border: 2px solid var(--color-light-grey);
  border-radius: var(--border-radius);
  background: var(--dropdown-selected-background-color);
  padding: 0 13px;
  font-size: 1.4rem;
  position: relative;

  .selected {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    color: var(--primary-font-color);
    border-radius: var(--border-radius);
  }

  .arrow {
    width: 1.5rem;
    height: 1.5rem;
    background-image: url("../../../assets/icons/dropdown-arrow-dark.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: 0.3s transform ease-in-out;
  }

  .list {
    position: absolute;
    top: 100%;
    display: none;
    width: 100%;
    background-color: var(--dropdown-selected-background-color);
    border-top: 0.1rem solid var(--navigation-icon-background);
    border-radius: 0 0 0.4rem 0.4rem;
    box-shadow: var(--shadow-box);
    left: 0;

    .valueContainer {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 700;
      color: var(--primary-font-color);
      padding: 8px;
      cursor: pointer;

      .valueName {
        padding: 0 0.5rem;
      }
    }

    .value:hover {
      background-color: var(--dropdown-hover-background-color);

      .valueName {
        color: #11243b;
      }
    }
  }

  &.opened {
    z-index: 4;

    .list {
      display: block;
    }

    .selected {
      border-radius: 0.4rem 0.4rem 0 0;
      //box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);

      .arrow {
        transform: rotate(180deg);
      }
    }

  }
}


:global(.isDesktop) {
  .dropdown {
    max-width: 230px;
    display: inline-flex;
  }
}

:global(.dark-theme) {
  .dropdown .arrow {
    background-image: url("../../../assets/icons/dropdown-arrow-light.svg");
  }
}