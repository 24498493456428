.barChartContainer {
  padding: 1.5rem;
  border-radius: 0.6rem;
  background-color: var(--primary-background-color);
  box-shadow: var(--shadow-box);

  .header {
    margin-bottom: 1.5rem;
    color: var(--primary-font-color);
    font-size: 1.8rem;
    font-weight: 700;
  }

  .barContainer {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .barName {
      color: var(--secondary-font-color);
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 700;
    }

    .bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .color {
        position: relative;
        width: 89%;
        height: 1rem;
        border-radius: var(--border-radius);
        background-color: var(--dropdown-background-color);

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: var(--bar-width);
          background-color: var(--bar-color);
          border-radius: var(--border-radius);
        }
      }

      .percentage {
        color: var(--primary-font-color);
        font-size: 1.2rem;
        font-weight: 700;
      }
    }
  }
}

:global(.isDesktop) {
  .barChartContainer .barContainer {
    .barName {
      font-size: 1.2rem;
    }

    .bar .percentage {
      font-size: 1.4rem;
    }
  }
}

.loader {
  margin: 1rem 0;
  text-align: center;
}
