.header {
  font-family: Nunito;
  font-size: 28px;
  margin-bottom: 8px;
  line-height: 40px;
  color: var(--primary-font-color);
}

.headerWrapper {
  display: block;
  position: relative;
  margin-bottom: 20px;
  margin-top: 30px;
}

:global(.isDesktop) {
  .header {
    display: inline-flex;
    margin-right: 40px;
    margin-bottom: 0;
  }
  .headerWrapper {
    display: flex;
    align-items: center;
    height: 40px;
  }
}
