.wrapper {
  margin: 0px 0px 23px 0px;
  padding-bottom: 35px;
  border-bottom: 1px solid var(--secondary-background-color);
  &.header {
    display: none;
  }
}

.header {
  font-size: 1.4rem;
  font-weight: bold;
  font-family: Nunito, sans-serif;
  display: flex;
  margin-bottom: 15px;
  line-height: 24px;

  img {
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }
}

.value {
  font-size: 1.4rem;
  margin-bottom: 15px;

}

.attribute {
  font-size: 1.4rem;
  margin-bottom: 10px;
  display: flex;
}

.attributeName {
  color: var(--color-grey);
  display: inline-flex;
  width: 115px;
  min-width: 115px;
}

.location {
  display: flex;
}

.time {
  font-weight: bold;
}

.warning {
  color: var(--color-red)
}

.confirmButton {
  border: none;
  text-decoration: underline;
  background: none;
  padding: 0;
  font-size: 1.4rem;
  cursor: pointer;
  color: var(--primary-font-color);
}


:global(.isDesktop) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 0;
    &.header {
      display: grid;
    }
  }

  .header,
  .value,
  .attribute {
    margin-bottom: 0;
    line-height: 24px;
  }

  .confirmButton {
    display: inline-flex;
  }

  .body {
    .attributeName {
      display: none;
    }
  }
}