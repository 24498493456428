.locationWrapper {
  background: var(--primary-background-color);
  box-shadow: var(--shadow-box);
  border-radius: 0.6rem;
  font-weight: 700;
  cursor: pointer;

  .locationHeader {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.1rem solid var(--secondary-background-color);

    .locationName {
      color: var(--primary-font-color);
      font-size: 1.8rem;
    }

    .locationAlerts {
      padding: 0 0.6rem;
      background-color: var(--color-red);
      color: var(--white-font-color);
      border-radius: var(--border-radius);
      font-size: 1.5rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .locationMain {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .chartContainer,
    .storageContainer {
      padding: 2rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: clamp(10.3rem, 30%, 11.5rem);

      .chartTitle {
        color: var(--secondary-font-color);
        font-size: 1.2rem;
        text-transform: uppercase;
      }

      .timeContainer,
      .employeesContainer {
        .title {
          color: var(--tertiary-font-color);
          text-align: center;
          font-size: 1rem;
          text-transform: uppercase;
        }
      }

      .employeesContainer {
        width: 100%;

        .title {
          text-align: start;
        }
      }

      .timeContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .time {
          display: flex;

          .title {
            color: var(--black-font-color);
            font-size: 1.6rem;
          }

          .percentage {
            margin-left: 0.5rem;
            display: flex;
            align-items: center;
            font-size: 1.2rem;

            .arrows {
              margin-right: 0.2rem;
              width: 1rem;
              height: 100%;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;

              &.arrowUp {
                background-image: url(../../../assets/icons/arrow-up.svg);

                & + span {
                  color: var(--color-green);
                }
              }

              &.arrowDown {
                background-image: url(../../../assets/icons/arrow-down.svg);

                & + span {
                  color: var(--color-red);
                }
              }
            }
          }
        }
      }
    }

    .chartContainer {
      position: relative;

      .backgroundCircle {
        &::before {
          content: "";
          position: absolute;
          top: 25%;
          left: 9%;
          width: 9.6rem;
          height: 9.6rem;
          border: 0.2rem solid var(--secondary-background-color);
          border-radius: 50%;
        }
      }
    }

    .storageContainer {
      padding: 2rem 1.5rem;
      width: clamp(10.3rem, 38%, 11.5rem);
      border-radius: var(--border-radius);
      background-color: var(--secondary-background-color);

      .chartTitle {
        width: 100%;
      }

      .storageChartContainer {
        padding: 2.3rem 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        .lastDayBar {
          position: relative;
          width: 0.8rem;
          background-color: var(--navigation-icon-background);
          border-radius: 0.7rem;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: var(--bar-height);
            background-color: var(--bar-color);
            border-radius: 0.7rem;
          }
        }

        .barsContainer {
          display: flex;
          flex-direction: column;
          width: 75%;

          .barsTitle {
            padding-bottom: 0.5rem;
            width: 100%;

            .title {
              color: var(--primary-font-color);
              font-size: 2.4rem;
            }

            .text {
              color: var(--tertiary-font-color);
              font-size: 1rem;
            }
          }

          .barsChart {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            height: 100%;
            min-height: 4.45rem;

            .singleBar {
              width: 0.5rem;
              height: var(--bar-height);
              background-color: var(--bar-color);
              border-radius: 0.7rem;
            }
          }
        }
      }

      .count {
        color: var(--primary-font-color);
        font-size: 1.6rem;
      }
    }
  }
}

:global(.isDesktop) {
  .locationWrapper {
    .locationMain {
      padding: 1.5rem;

      .storageContainer {
        width: clamp(10.3rem, 38%, 14.5rem);

        .storageChartContainer {
          .lastDayBar {
            width: 1.2rem;
          }

          .barsContainer {
            .title {
              font-size: 2.8rem;
            }

            .singleBar {
              width: 0.9em;
            }
          }
        }
      }

      .chartContainer .chartTitle,
      .storageContainer .chartTitle {
        font-size: 1.4rem;
      }

      .chartContainer {
        width: clamp(10.3rem, 30%, 13.5rem);

        .backgroundCircle {
          &::before {
            top: 24.5%;
            left: 11%;
            width: 10.85rem;
            height: 10.85rem;
          }
        }
      }

      .timeContainer .title,
      .employeesContainer .title {
        font-size: 1.2rem;
      }
    }
  }
}

:global(.isSmallMobile) {
  .locationWrapper .locationMain .chartContainer .backgroundCircle {
    &::before {
      left: 4%;
      width: 9.7rem;
      height: 9.7rem;
    }
  }
}

.loader {
  margin: 1rem 0;
  text-align: center;
}
