.storageContainer {
  margin-bottom: 1rem;
  padding: 1.5rem;
  border-radius: 0.6rem;
  background-color: var(--primary-background-color);
  box-shadow: var(--shadow-box);

  .header {
    margin-bottom: 1rem;
    color: var(--primary-font-color);
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: capitalize;
  }

  .main {
    display: flex;

    .overallBar {
      margin-right: 2rem;
      position: relative;
      width: 3.5rem;
      background-color: var(--secondary-background-color);
      border-radius: var(--border-radius);

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: var(--bar-height);
        background-color: var(--bar-color);
        border-radius: var(--border-radius);
      }
    }

    .storageInfo {
      flex: 1;

      .overallInfo {
        display: flex;
        justify-content: space-between;
        font-weight: 700;

        .overallPercentageContainer {
          .overallPercentage {
            color: var(--primary-font-color);
            font-size: 2.4rem;
          }

          .overallText {
            color: var(--secondary-font-color);
            font-size: 1rem;
            text-transform: uppercase;
          }
        }

        .optimizationButton {
          padding: 0.5rem 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: 14.5rem;
          width: 100%;
          background-color: var(--color-red);
          border-radius: var(--border-radius);

          .text {
            margin-right: 0.5rem;
            color: #fff;
            font-size: 1.2rem;
          }

          .icon,
          .arrow {
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }

          .icon {
            margin-right: 1rem;
            width: 4rem;
            background-image: url("../../../assets/icons/bulb.svg");
          }

          .arrow {
            width: 1.4rem;
            background-image: url("../../../assets/icons/arrow-right.svg");
          }
        }
      }

      .storageBarsContainer {
        margin-top: 1.5rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .singleBarContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: var(--primary-font-color);

          &:not(:last-child) {
            margin-right: 0.5rem;
          }

          .singleBarPercentage {
            order: 3;
            font-size: 1.1rem;
            font-weight: 700;
          }

          .singleBar {
            margin-bottom: 0.5rem;
            position: relative;
            min-width: 2.4rem;
            height: 8.5rem;
            background-color: var(--secondary-background-color);
            border-radius: var(--border-radius);

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: var(--bar-height);
              background-color: var(--bar-color);
              border-radius: var(--border-radius);
            }
          }

          .singleBarName {
            display: none;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}

.loader {
  text-align: center;
}

:global(.isDesktop) {
  .storageContainer {
    padding: 2.5rem;

    .main {
      .overallBar {
        width: 8rem;
      }

      .storageInfo {
        display: flex;

        .overallInfo {
          margin-right: 5rem;
          flex-direction: column;

          .overallPercentageContainer {
            min-width: 10rem;

            .overallPercentage {
              font-size: 4.4rem;
              line-height: 4.5rem;
            }
          }



          .optimizationButton {
            margin-top: 2.5rem;
            cursor: pointer;
          }
        }

        .storageBarsContainer {
          width: 100%;

          .singleBarContainer {
            &:not(:last-child) {
              margin-right: 1.5rem;
            }

            .singleBarPercentage {
              margin-bottom: 1rem;
              order: 0;
              font-size: 1.6rem;
            }

            .singleBar {
              min-width: 4rem;
            }

            .singleBarName {
              display: block;
            }
          }
        }
      }
    }
  }
}
