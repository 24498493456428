.confirmationText {
  font-size: 1.6rem;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  button {
    margin-left: 20px;
    background: var(--color-green);
    height: 40px;
    padding: 0 20px;
    color: var(--white-font-color);
    border-radius: var(--border-radius);
    border: none;
    font-size: 1.6rem;
    cursor: pointer;
  }
  .cancel {
    font-size: 1.4rem;
    color: var(--color-red);
    font-weight: bold;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}