.onlyDesktop {
  display: none;
}
@media screen and (min-width: 1200px) {
  .noWrapper {
    display: flex;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    .showAlertsButton {
      position: absolute;

    }
    .onlyDesktop {
      display: initial;
      position: relative;
      min-width: 35rem;
      margin-top: -1rem;
      margin-right: -1rem;
      margin-bottom: -1rem;
      padding: 1rem;
      background: var(--primary-background-color);
      .closeButton {
        position: absolute;
        padding: 0;
        top: 5rem;
        right: 4rem;
        background: none;
        border: none;
        cursor: pointer;
      }
    }
    .dummy {
      display: initial;
      position: relative;
      min-width: 1rem;
    }
  }
  .locationContainer {
    padding: 2rem 3rem;

    .header {
      margin-bottom: 3rem;
      display: flex;
      align-items: center;
    }
  }
}