.dropdownContainer {
  position: relative;
  top: -1rem;
  left: -1rem;
  width: 100vw;
  box-shadow: var(--shadow-box);

  &.opened {
    .selectedLocation .arrow {
      transform: rotate(180deg);
    }

    .locationsList {
      display: block;
    }
  }

  .selectedLocation {
    position: relative;
    border-top: 0.2rem solid var(--secondary-background-color);
    z-index: 2;

    .name.active {
      color: var(--selected-font-color);
    }

    .arrow {
      width: 1.5rem;
      height: 1.5rem;
      background-image: url("../../../assets/icons/dropdown-arrow-dark.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition: 0.3s transform ease-in-out;
    }
  }

  .locationsList {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    width: 100%;
    z-index: 2;
    box-shadow: var(--shadow-box);

    .dropdownLocation .info {
      margin-right: 2.5rem;
    }
  }

  .selectedLocation,
  .dropdownLocation {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    background-color: var(--primary-background-color);
    cursor: pointer;

    .name {
      font-size: 1.8rem;
      font-weight: bold;
      color: var(--primary-font-color);
    }

    .info {
      margin-left: auto;
      margin-right: 1rem;
      display: flex;

      .radialChart {
        margin: -3.3rem;
        transform: scale(0.38);
      }

      .barChart {
        margin-right: 0.5rem;
        position: relative;
        width: 0.8rem;
        background-color: var(--secondary-background-color);
        border-radius: 0.2rem;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: var(--bar-height);
          background-color: var(--bar-color);
          border-radius: 0.2rem;
        }
      }

      .alerts {
        padding: 0 0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--border-radius);
        background-color: var(--color-red);
        color: var(--white-font-color);
        font-size: 1.6rem;
        font-weight: 600;

        &.noAlerts {
          background-color: #edf1f4;
          color: var(--disabled-font-color);
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.2rem);
  z-index: 1;
}

:global(.isDesktop) {
  .dropdownContainer {
    margin-right: 3.5rem;
    top: 0;
    left: 0;
    width: 37.5rem;

    .selectedLocation {
      border: none;
    }

    .selectedLocation .name,
    .dropdownLocation .name {
      font-size: 2.8rem;
    }

    .info .alerts {
      display: none;
    }
  }
}

:global(.dark-theme) {
  .dropdownContainer .selectedLocation.arrow {
    background-image: url("../../../assets/icons/dropdown-arrow-light.svg");
  }
}
