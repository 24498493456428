.dropdownContainer {
  position: relative;
  color: var(--primary-font-color);

  &.opened {
    z-index: 4;

    .selectedComparisonOption::after {
      content: "";
      position: absolute;
      top: -1rem;
      left: -1rem;
      bottom: -1rem;
      right: -1rem;
      border-radius: var(--border-radius);
      background-color: var(--dropdown-selected-background-color);
      box-shadow: var(--calendar-shadow-box);
      z-index: -1;
    }

    .comparisonOptionsContainer {
      display: block;
      min-width: 14.5rem;
    }
  }

  .selectedComparisonOption {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;

    &.default {
      color: #88929d;
    }
  }

  .comparisonOptionsContainer {
    display: none;
    position: absolute;
    top: calc(100% + 1.5rem);
    right: -1rem;
    overflow: hidden;
    background-color: var(--dropdown-selected-background-color);
    border-radius: var(--border-radius);
    box-shadow: var(--calendar-shadow-box);

    .comparisonOptionsList {
      padding: 0.75rem 0;
      width: 100%;

      .comparisonOption {
        padding: 0.75rem 1.5rem 0.75rem;
        font-size: 1.2rem;
        font-weight: 700;
        cursor: pointer;

        &.selected {
          color: var(--chart-color-blue);
          background-color: var(--dropdown-hover-background-color);
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.2rem);
  z-index: 3;
}
