.storageDropdownContainer {
  position: relative;
  width: 48%;

  &.opened {
    z-index: 4;

    .selectedKpi {
      border-radius: 0.4rem 0.4rem 0 0;
      background-color: var(--dropdown-selected-background-color);
      box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);

      .arrow {
        transform: rotate(180deg);
      }
    }

    .kpiList {
      display: block;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .selectedKpi {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--dropdown-background-color);
    border-radius: var(--border-radius);

    .arrow {
      width: 1.5rem;
      height: 1.5rem;
      background-image: url("../../../../assets/icons/dropdown-arrow-dark.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition: 0.3s transform ease-in-out;
    }
  }

  .kpiList {
    position: absolute;
    top: 100%;
    display: none;
    width: 100%;
    background-color: var(--dropdown-selected-background-color);
    border-top: 0.1rem solid var(--navigation-icon-background);
    border-radius: 0 0 0.4rem 0.4rem;
    box-shadow: var(--shadow-box);

    .dropdownKpi:hover {
      background-color: var(--dropdown-hover-background-color);

      .kpiContainer .kpiName {
        color: #11243b;
      }
    }
  }

  .selectedKpi,
  .dropdownKpi {
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    .kpiContainer {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 700;
      color: var(--primary-font-color);

      .kpiColor {
        display: inline-block;
        width: 1.2rem;
        min-width: 1.2rem;
        height: 1.2rem;
        border-radius: var(--border-radius);
        background-color: var(--bg-color);

        &.default {
          border: 0.1rem solid var(--navigation-icon-background);
        }
      }

      .kpiName {
        padding: 0 0.5rem;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.2rem);
  z-index: 3;
}

:global(.isDesktop) {
  .storageDropdownContainer {
    .selectedKpi .kpiContainer,
    .dropdownKpi .kpiContainer {
      font-size: 1.4rem;
    }
  }
}

:global(.dark-theme) {
  .storageDropdownContainer .selectedKpi .arrow {
    background-image: url("../../../../assets/icons/dropdown-arrow-light.svg");
  }
}
