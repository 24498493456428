

:global(.isDesktop) {
  .weekdays {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 20px;
  }
}