.onlyDesktop {
  display: none;
}
:global(.isDesktop) {
  .showAlertsButton {
    border: none;
    position: absolute;
    right: 0;
    padding: 0;
  }

  .locationContainer {
    padding: 2rem 3rem;
    flex-grow: 1;
    min-width: 0;

    .header {
      margin-bottom: 3rem;
      display: flex;
      position: relative;
      align-items: center;
    }
  }
}