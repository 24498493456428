.navigation {
  position: relative;
  z-index: 3;

  .toggleButtonContainer {
    display: none;
  }

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    width: 100%;
    height: clamp(4rem, 7vh, 4.65rem);
    background: var(--primary-background-color);

    .logo {
      width: 14rem;
      height: 100%;
      background-image: url("../../../assets/logo/logo-title-light.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      cursor: pointer;
    }

    .menuIcon {
      cursor: pointer;
    }
  }

  .logoutMenuItem{
    display: none;
  }

  .avatarWrapper{
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }

  .navWrapper {
    position: absolute;
    right: -100vw;
    display: flex;
    flex-direction: column;
    padding: 4rem 3rem;
    width: 100%;
    // height: clamp(100vh - 4rem, 93vh, 100vh - 4.65rem);
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 4.65rem);
    overflow-y: auto;
    background: var(--primary-background-color);
    transition: 0.5s all ease-in-out;
    // hides scrollbar in webkit browsers
    &::-webkit-scrollbar {
      display: none;
    }
    // hides scrollbar in firefox
    scrollbar-width: none;

    &.show {
      right: 0;
    }

    .avatarContainer {
      margin-bottom: 2rem;

      .avatar {
        width: 7.2rem;
        height: 7.2rem;
        font-size: 3rem;
      }

      .avatarText,
      .logoutButton {
        display: none;
      }
    }

    .mainMenuItems {
      font-size: 3.2rem;
      font-weight: 600;
    }

    .divider {
      margin: 3.6rem 0;
      width: 2rem;
      height: 1px;
      background: var(--black-font-color);
    }

    .subMenuItems {
      font-size: 2rem;
    }

    .menuItem {
      &.hide {
        display: none;
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      .menuItemIcon {
        display: none;
      }

      .menuLink {
        display: block;
        text-transform: capitalize;
        color: var(--primary-font-color);
        letter-spacing: 0.05rem;
      }
    }

    .themeButtonContainer {
      margin-top: auto;
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .themeButton {
        width: 2.5rem;
        height: 2.5rem;
        background-image: url("../../../assets/icons/dark-theme-button.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
  }
}

.main {
  padding: 1rem;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-background-color);
  overflow: auto;

  // hides scrollbar in webkit browsers
  &::-webkit-scrollbar {
    display: none;
  }
  // hides scrollbar in firefox
  scrollbar-width: none;
}

:global(.isDesktop) {
  .navigation {
    width: 7rem;
    min-width: 7rem;
    transition: none;

    .toggleButtonContainer {
      position: absolute;
      top: 0.2rem;
      right: -2.1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0.5rem;
      background-color: var(--navigation-icon-background);
      border-radius: 0 0.3rem 0.3rem 0;
      cursor: pointer;

      .toggleIcon {
        width: 1rem;
        height: 1rem;
        background-image: url("../../../assets/icons/navigation-arrow.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    .headerWrapper {
      height: clamp(6.5rem, 7vh, 6.5rem);
      border-bottom: 0.1rem solid var(--secondary-background-color);

      .logo {
        width: 4.9rem;
        background-image: url("../../../assets/logo/logo.svg");
      }

      .menuIcon {
        display: none;
      }
    }

    .navWrapper {
      left: 0;
      padding: 0;
      height: clamp(100vh - 6.5rem, 93vh, 100vh - 6.5rem);

      .mainMenuItems,
      .subMenuItems,
      .avatarContainer {
        padding: 1rem;
      }

      .avatarContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0;
        padding: 1rem 2rem;
        order: 4;
        border-top: 1px solid var(--secondary-background-color);

        .avatar {
          width: 3rem;
          height: 3rem;
          font-size: 1.3rem;
        }

        .avatarText,
        .logoutButton {
          opacity: 0;
        }

        .avatar,
        .avatarText,
        .logoutButton {
          cursor: pointer;
        }
      }

      .menuItem {
        &:not(:last-child) {
          margin-bottom: 0;
        }

        a {
          padding: 1.5rem;
          display: flex;
          align-items: center;
          min-height: 5.2rem;
          border-radius: 0.5rem;
          cursor: pointer;

          &.selected {
            background-color: #edf1f4;


            & .menuLink {
              color: #11243b;
            }
          }

          .menuItemIcon {
            display: block;
            margin: 0 auto;
            width: 2rem;
            height: 2rem;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: var(--background-image-url);
          }

          .menuLink {
            display: none;
            opacity: 0;
          }
        }
      }

      .logoutMenuItem{
        padding: 0 1rem;
        order: 5;
        display: block;

        a{
          padding: 0 1.5rem 1.5rem 1.5rem
        }
      }

      .menuLink,
      .avatarText {
        color: var(--primary-font-color);
        font-size: 1.6rem;
      }

      .divider {
        margin: 0;
        width: 100%;
        background-color: var(--secondary-background-color);
      }

      .themeButtonContainer {
        margin-bottom: 2.5rem;
        justify-content: center;

        .themeButton {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    &.expand {
      width: 23rem;
      min-width: 23rem;

      .toggleButtonContainer .toggleIcon {
        transform: rotate(180deg);
      }

      .headerWrapper .logo {
        width: 15.5rem;
        background-image: url("../../../assets/logo/logo-title-light.svg");
      }

      .navWrapper {
        .menuItem {
          .menuLink {
            display: block;
            animation: fadeIn 0.5s forwards;
          }

          .menuItemIcon {
            margin: 0 1.3rem 0 0;
          }
        }

        .avatarContainer {
          padding: 1rem 2rem;

          .avatarText,
          .logoutButton {
            display: block;
            animation: fadeIn 0.5s 0s forwards;
          }

          .logoutButton {
            margin-left: auto;
            width: 0;
            height: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }

          .avatar {
            margin-right: .8rem;
          }
        }

        .themeButtonContainer {
          padding: 0 2.5rem;
          justify-content: flex-start;
        }
      }
    }
  }

  .main {
    // shows scrollbar in webkit browsers
    &::-webkit-scrollbar {
      display: block;
    }
    // shows scrollbar in firefox
    scrollbar-width: block;
  }
}

:global(.dark-theme) {
  .navigation {
    .toggleButtonContainer .toggleIcon {
      background-image: url("../../../assets/icons/dropdown-arrow-dark.svg");
      transform: rotate(-90deg);
    }

    &.expand {
      .headerWrapper .logo {
        background-image: url("../../../assets/logo/logo-title-dark.svg");
      }

      .toggleButtonContainer .toggleIcon {
        transform: rotate(90deg);
      }
    }

    .navWrapper .themeButtonContainer .themeButton {
      background-image: url("../../../assets/icons/light-theme-button.svg");
    }

    .headerWrapper .menuIcon {
      color: #fff;
    }
  }
}

:global(.isMobile.dark-theme) {
  .navigation {
    .headerWrapper .logo {
      background-image: url("../../../assets/logo/logo-title-dark.svg");
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
