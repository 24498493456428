.overviewTabContainer {
  margin-bottom: 1rem;
  padding: 2rem 1.5rem 1.5rem;
  border-radius: 0.6rem;
  background-color: var(--primary-background-color);
  box-shadow: var(--shadow-box);

  .header {
    margin-bottom: 0.5rem;
    color: var(--location-menu-font-color);
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .goal {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;

    .infoContainer {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;

      .info {
        .goalCompletion {
          color: var(--primary-font-color);
          font-size: 2.4rem;
        }

        .secondaryKpi {
          color: var(--secondary-font-color);
          font-size: 1rem;
        }
      }

      .percentage {
        color: var(--bar-color);
        font-size: 3.4rem;
      }
    }

    .bar {
      position: relative;
      width: 100%;
      height: 1rem;
      background-color: var(--secondary-background-color);
      border-radius: var(--border-radius);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: var(--bar-width);
        background-color: var(--bar-color);
        border-radius: var(--border-radius);
      }
    }
  }

  .avgTimesContainer {
    display: flex;

    .avgTime {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .title {
        color: var(--secondary-font-color);
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
      }

      .timeContainer {
        display: flex;
        font-weight: 700;

        .time {
          color: var(--black-font-color);
          font-size: 1.6rem;
        }

        .percentage {
          margin-left: 0.5rem;
          display: flex;
          align-items: center;
          font-size: 1.2rem;

          .arrow {
            margin-right: 0.2rem;
            width: 1rem;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;

            &.arrowUp {
              background-image: url(../../../assets/icons/arrow-up.svg);

              & + span {
                color: var(--color-green);
              }
            }

            &.arrowDown {
              background-image: url(../../../assets/icons/arrow-down.svg);

              & + span {
                color: var(--color-red);
              }
            }
          }
        }
      }
    }
  }
}

:global(.isDesktop) {
  .overviewTabContainer {
    margin-bottom: 3rem;
    padding: 3rem 2.5rem 2.5rem;

    .header {
      font-size: 1.4rem;
    }

    .goal .infoContainer .info .secondaryKpi,
    .avgTimesContainer .avgTime .title {
      font-size: 1.2rem;
    }
  }
}

.loader {
  margin: 1rem 0;
  text-align: center;
}
