@media screen and (min-width: 1200px){
  .onlyMobile {
    display: none;
  }
  .inboundTab {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .chart:not(:last-child) {
      margin-bottom: 3rem;
    }

    .chart:nth-child(4) {
      order: 3;
    }

    .overview {
      width: 100%;
    }

    .chart {
      width: 48%;
    }
  }
}

.inboundTab {
  .chart:not(:last-child) {
    margin-bottom: 1rem;
  }
}
