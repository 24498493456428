.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.2rem);
  z-index: 12;
}
.modal {
  width: 100vw;
  background: var(--primary-background-color);
  position: absolute;
  top: 10vh;
  left: 0;
  padding: 38px 20px 20px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: auto;
  z-index: 15;

  &.small {
    padding-top: 10px;
    position: fixed;
    width: 350px;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid var(--color-light-grey);
    border-radius: 8px;

    .header {
      margin-top: 10px;
      font-size: 1.8rem;
    }
  }
}

h3 {
  color: var(--primary-font-color);
}

.header {
  font-size: 28px;
  margin-bottom: 25px;
  color: var(--primary-font-color);
}
.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

:global(.isDesktop) {
  .modal {
    max-width: 70vw;
    max-height: 80vh;
    left: 50%;
    top: 10vh;
    transform: translateX(-50%);
    border: 2px solid var(--color-light-grey);
    border-radius: var(--border-radius);
  }
}
