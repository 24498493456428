.listElement {
  background: var(--color-red);
  border-radius: var(--border-radius);
  margin-bottom: 0.5rem;
  padding: 1.2rem;
  color: var(--white-font-color);
  display: flex;

  &.loading {
    opacity: 0.7;
  }

  .innerWrapper {
    .header,
    .value,
    .confirmButton {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin-right: 0.5rem;
    }

    .header {
      font-weight: bold;
    }
    .confirmButton {
      border: none;
      background: none;
      color: var(--white-font-color);
      display: inline;
      text-decoration: underline;
      padding: 0;
      cursor: pointer;
      &:disabled {
        pointer-events: none;
      }
    }
  }
  .alertIcon {
    width: 2.5rem;
    height: auto;
    margin-right: 1rem;
  }

}

