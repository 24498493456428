.moreButton {
  margin-bottom: 0.5rem;
  padding: 1.4rem 0;
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: var(--border-radius);
  background: var(--navigation-icon-background);
  color: var(--font-color);
  cursor: pointer;

  .innerWrapper {
    font-size: 1.4rem;
    line-height: 100%;
    font-weight: bold;
    color: var(--primary-font-color);
  }

  .icon {
    width: auto;
    height: 1.2rem;
    margin-right: 2rem;
    margin-left: 2.4rem;
  }
}

:global(.isDesktop) {
  .moreButton {
    background: var(--alerts-button-background);
  }
}