.input {
  width: 100%;
  height: 40px;
  border: 2px solid var(--color-light-grey);
  border-radius: var(--border-radius);
  padding: 13px;
  font-size: 1.4rem;
  margin-bottom: 20px;
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.5; /* Firefox */
  }
}

.label {
  width: 100%;
  font-size: 1.6rem;
  margin-bottom: 5px;
  color: var(--primary-font-color);
}

:global(.isDesktop) {
  .input {
    max-width: 400px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
}