.lineChart {
  margin: 1rem -1rem -1rem;

  &.xaxisLabels {
    margin: 0.5rem 0 0;
  }
}

.loader {
  margin: 1rem 0;
  text-align: center;
}