.storageOverviewContainer {
  padding: 1.5rem;
  border-radius: 0.6rem;
  background-color: var(--primary-background-color);
  box-shadow: var(--shadow-box);

  .header {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--primary-font-color);
    font-weight: bold;

    .name {
      font-size: 1.8rem;
      text-transform: capitalize;
    }
  }

  .overallInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;

    .overallPercentageContainer {
      .overallPercentage {
        color: var(--primary-font-color);
        font-size: 2.4rem;
      }

      .overallText {
        color: var(--location-menu-font-color);
        font-size: 1.2rem;
        text-transform: uppercase;
      }
    }

    .optimizationButton {
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 17rem;
      width: 100%;
      height: 2.5rem;
      background-color: var(--color-red);
      border-radius: var(--border-radius);
      cursor: pointer;

      .text {
        color: #fff;
        font-size: 1.2rem;
      }

      .icon,
      .arrow {
        height: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .icon {
        width: 1.5rem;
        background-image: url("../../../assets/icons/bulb.svg");
      }

      .arrow {
        width: 0.7rem;
        background-image: url("../../../assets/icons/arrow-right.svg");
      }
    }
  }

  .chartContainer {
    .dropdowns {
      display: flex;
      justify-content: space-between;
    }
  }
}

:global(.isDesktop) {
  .storageOverviewContainer .overallInfo .overallPercentageContainer .overallPercentage {
    font-size: 4.4rem;
    line-height: 5rem;
  }
}

.loader {
  margin: 1rem 0;
  text-align: center;
}
