.createButton {
  background: var(--color-green);
  width: 100%;
  height: 50px;
  color: var(--white-font-color);
  border-radius: var(--border-radius);
  border: none;
  font-size: 1.6rem;
  margin-top: 25px;
  cursor: pointer;
  margin-bottom: 20px;
}

.delete {
  font-size: 1.4rem;
  color: var(--color-red);
  font-weight: bold;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.note {
  color: var(--primary-font-color);
}