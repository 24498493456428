.pagination {
  display: flex;
  justify-content: space-between;
}

.arrow {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../../../assets/icons/dropdown-arrow-dark.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: 0.3s transform ease-in-out;
}

.disabled {
  opacity: 0.5;
}

.prevButton,
.nextButton {
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: var(--primary-background-color);
  border: 2px solid var(--color-light-grey);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
}

.nextButton {
  transform: rotate(-90deg);
}

.prevButton {
  transform: rotate(90deg);
}

.page {
  background: var(--color-light-grey);
  color: var(--primary-font-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.4rem;
}

:global(.isDesktop) {
  .pagination {
    margin-top: 20px;
  }
}

:global(.dark-theme) {
  .arrow {
    background-image: url("../../../assets/icons/dropdown-arrow-light.svg");
  }
}
