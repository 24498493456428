.createButton {
  background: var(--color-green);
  width: 100%;
  height: 50px;
  color: var(--white-font-color);
  border-radius: var(--border-radius);
  border: none;
  font-size: 1.6rem;
  margin-top: 25px;
  margin-bottom: 20px;
  cursor: pointer;
}

:global(.isDesktop) {
  .createButton {
    width: auto;
    padding: 0 20px;
    margin-left: 20px;
  }
}