.overlayContainer {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2rem);
  z-index: 3;

  .logo {
    width: 10rem;
    height: 3rem;
    background-image: url("../../assets/logo/logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .text {
    color: var(--primary-font-color);
    font-size: 2rem;
  }
}

:global(.isDesktop) {
  .overlayContainer {
    .logo {
      width: 14rem;
      height: 7rem;
    }

    .text {
      font-size: 5rem;
    }
  }
}