.dashboardWrapper {
  display: flex;
  flex-wrap: wrap;

  .greetings {
    display: none;
    width: 100%;
    color: var(--primary-font-color);
  }

  .locationOverviewWrapper {
    margin: 0 auto;
    width: clamp(35.5rem, 100%, 50rem);

    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }
}

:global(.isDesktop) {
  .dashboardWrapper {
    padding: 2rem 3rem;

    .greetings {
      margin-bottom: 3rem;
      display: block;
      font-size: 2.8rem;
      font-weight: 700;
    }

    .locationOverviewWrapper {
      padding: 0 2rem 2rem 0;
    }
  }
}
