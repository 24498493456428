.profileCard{
    width: 100%;
    position: relative;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
    border-radius: .6rem;
    background-color: var(--primary-background-color);
    padding: 2.5rem;
    color: var(--primary-font-color);
}

.header {
  font-family: Nunito;
  font-size: 2.8rem;
  margin-bottom: .8rem;
  line-height: 4rem;
  color: var(--primary-font-color);
}

.headerWrapper {
  display: block;
  position: relative;
  margin-bottom: 2rem;
  margin-top: 3rem;
}

.tabNavigationWrapper{
    display: flex;
    margin-bottom: 2.5rem;
}
.tabHeadline{
    display: none;
}

.tabItem{
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--primary-font-color);
    margin-bottom: 0;
    position: relative;

    &:first-of-type{
        margin-right: 1.5rem;
    }

    &::after{
        content: "";
        position: absolute;
        height: .1rem;
        width: 0%;
        background-color: var(--primary-font-color);
        left: 0;
        bottom: 0;
        transition: .2s;
    }
}

.tabItemActive{
    &::after{
        width: 100%;
    }
}

.submitWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.submitButton{
    background-color: #2fc07d;
    border-radius: .4rem;
    height: 5rem;
    color: #fff;
    font-weight: 700;
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    cursor: pointer;
    border: none;
    transition: .2s;
    font-size: 1.8rem;
    margin-bottom: 2rem;

    &:hover, &:focus{
        background-color: #507ee3;
    }

    &:disabled, &:disabled:hover{
        background:#d9d9d9;
        cursor: not-allowed;
    }
}

.deleteButton{
    font-size: 1.4rem;
    color: #EB5757;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
    transition: .2s;

    &:hover{
        color: #EB5757;
        text-decoration: none;
    }
}

.inputField{
    margin: .5rem 0 1.5rem;
    width: 100%;
    height: 4rem;
    border: .2rem solid var(--secondary-background-color);
    line-height: 3.6rem;
    font-size: 1.6rem;
    border-radius: .4rem;
    padding: 0 1.2rem;
    color: #11243b;

    &:focus{
        border: .2rem solid #2fc07d;
    }

    &:last-of-type{
        margin-bottom: 0;
    }
}

.inputFieldError{
    border-color: #EB5757 !important;
}

.inputLabel{
    font-size: 1.6rem;
    color: var(--primary-font-color);
}

.inputFieldWrapper{
    display: flex;
    flex-direction: column;
}

.errorWrapper{
    height: 4rem;
    border-radius: .4rem;
    background: #EB5757;
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    line-height: 4rem;
    font-weight: 700;
    margin-top: 2rem;
}

:global(.isDesktop) {
    .profileCard{
        padding: 3.3rem 4rem 4rem 2.5rem;
    }
    .passwordTab{
        padding-left: 5.7rem;
        border-left: .1rem solid var(--secondary-background-color);
        margin-left: 10rem;
    }
    .header {
        display: inline-flex;
        margin-right: 4rem;
        margin-bottom: 0;
    }
    .headerWrapper {
        display: flex;
        align-items: center;
        height: 4rem;
    }
    .tabNavigationWrapper{
        display: none;
    }
    .tabWrapper{
        display: flex;
    }

    .tabHeadline{
        display: inline-block;
        font-size: 2.4rem;
        margin-bottom: 3rem;
    }
    .submitWrapper{
        flex-direction: row;
        justify-content: space-between;
        margin-top: 4rem;
        border-top: .1rem solid var(--secondary-background-color);
        padding-top: 3.2rem;
    }
    .submitButton{
        width: 32rem;
        margin-bottom: 0;
    }

    .inputField{
        width: 32rem;
    }
}
