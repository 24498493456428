.kpiContainer {
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  border-radius: 0.6rem;
  background-color: var(--primary-background-color);
  box-shadow: var(--shadow-box);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--primary-font-color);
    font-weight: bold;

    .kpiName {
      font-size: 1.8rem;
      text-transform: capitalize;

      &.comparison {
        margin-right: auto;
      }
    }

    .delimiter {
      margin: 0 0.5rem;
    }
  }

  .timesContainer {
    margin: 1.5rem 0 1rem;
    display: flex;
    font-size: 1.2rem;
    font-weight: 700;

    .singleTime {
      text-transform: capitalize;
      color: var(--location-menu-font-color);
      cursor: pointer;

      &.selected {
        color: var(--primary-font-color);
        border-bottom: 0.1rem solid var(--primary-font-color);
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .dropdowns {
    display: flex;
    justify-content: space-between;
  }
}

:global(.isDesktop) {
  .kpiContainer .timesContainer {
    font-size: 1.4rem;
  }
}
