@media screen and (min-width: 1200px){
  .overviewTab {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .chart {
      width: 48%;

      &:first-child {
        margin-bottom: 0;
      }
    }

    .storage {
      margin-top: 3rem;
      width: 100%;
      order: 3;
    }
  }
}

.overviewTab {
  .chart:first-child {
    margin-bottom: 1rem;
  }
}