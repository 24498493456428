.alertListHeader {
  display: none;
}
@media screen and (min-width: 1200px) {

  .alertListHeader {
    margin-bottom: 4.5rem;
    margin-top: 2rem;
    display: flex;
    font-size: 1.8rem;
    color: var(--primary-font-color);
    cursor: pointer;

    &.separate {
      margin-top: 5rem;
    }
    .alertAmount {
      margin-left: 0.6rem;
      font-size: 1.6rem;
      font-weight: 400;
      color: var(--white-font-color);
      background: var(--color-red);
      border-radius: var(--border-radius);
      padding: 0 0.5rem;
      display: inline-flex;
    }
  }
}