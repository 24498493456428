.input {
  width: 100%;
  height: 4rem;
  border: .2rem solid var(--color-light-grey);
  border-radius: var(--border-radius);
  padding: 1.3rem;
  font-size: 1.4rem;
  margin-bottom: .8rem;
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.5; /* Firefox */
  }
  .input-wrapper{
    max-width: 100%;
  }

}

:global(.isDesktop) {
  .input{
    margin-bottom: 0;
  }
  .input-wrapper{
    max-width: 35rem;
    width: 100%;
    margin-right: 1.5rem;
  }
}
