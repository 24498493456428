.dropdownContainer {
  position: relative;
  color: var(--primary-font-color);

  &.opened {
    z-index: 4;

    .selectedTimeFrame::after {
      content: "";
      position: absolute;
      top: -1rem;
      left: -1rem;
      bottom: -1rem;
      right: -1rem;
      border-radius: var(--border-radius);
      background-color: var(--dropdown-selected-background-color);
      box-shadow: var(--calendar-shadow-box);
      z-index: -1;
    }

    .timeFramesContainer {
      display: flex;

      .calendarSection {
        display: block;
        min-width: 23.5rem;
        border-left: 1px solid var(--secondary-background-color);

        .calendarButtons {
          padding: 1rem 1.5rem;
          display: flex;
          justify-content: space-between;

          .singleCalendarButton {
            padding: 0.5rem;
            display: flex;
            align-items: center;
            width: 48%;
            min-width: 9.5rem;
            border-radius: var(--border-radius);
            color: #88929d;
            background-color: var(--dropdown-background-color);
            font-size: 1.2rem;
            font-weight: 700;
            cursor: pointer;

            &.active {
              color: var(--chart-color-blue);
              background-color: var(--white-font-color);
              box-shadow: var(--calendar-shadow-box);

              .calendarIcon {
                background-image: url("../../../assets/icons/calendar_blue.svg");
              }
            }

            .calendarIcon {
              margin-right: 0.5rem;
              width: 1.2rem;
              height: 1.2rem;
              background-image: url("../../../assets/icons/calendar.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
            }
          }
        }

        .applyButton {
          padding: 0.5rem;
          margin: 0 auto 1rem;
          width: 50%;
          color: #88929d;
          background-color: var(--dropdown-background-color);
          border-radius: var(--border-radius);
          font-size: 1.2rem;
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;

          &.active {
            color: var(--chart-color-blue);
            background-color: var(--white-font-color);
            box-shadow: var(--calendar-shadow-box);
            cursor: pointer;
          }
        }
      }
    }
  }

  .selectedTimeFrame {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;

    .calendarIcon {
      margin-right: 0.5rem;
      display: inline-block;
      width: 1.2rem;
      height: 1.2rem;
      background-image: url("../../../assets/icons/calendar.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .timeFramesContainer {
    display: none;
    position: absolute;
    top: calc(100% + 1.5rem);
    right: -1rem;
    overflow: hidden;
    background-color: var(--dropdown-selected-background-color);
    border-radius: var(--border-radius);
    box-shadow: var(--calendar-shadow-box);

    .timeFrameList {
      padding: 0.75rem 0;
      width: 100%;
      min-width: 11.5rem;

      .timeFrame {
        padding: 0.75rem 1.5rem 0.75rem;
        font-size: 1.2rem;
        font-weight: 700;
        cursor: pointer;

        &.selected {
          color: var(--chart-color-blue);
          background-color: var(--dropdown-hover-background-color);
        }
      }
    }

    .calendarSection {
      display: none;
    }
  }
}

:global(.dark-theme) {
  .dropdownContainer .selectedTimeFrame .calendarIcon {
    background-image: url("../../../assets/icons/calendar-dark.svg");
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.2rem);
  z-index: 3;
}
