.buttonWrapper {
  display: flex;
  flex-direction: column-reverse;
}

:global(.isDesktop) {
  .buttonWrapper {
    justify-content: flex-end;
    flex-direction: row;
    align-items: baseline;
    .createButton {
      margin-left: 20px;
    }
  }
}