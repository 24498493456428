:root {
	font-size: 10px;

  -webkit-font-smoothing: antialiased;
	--white-font-color: #fff;
	--disabled-font-color: #c4c4c4;
	--color-red: #eb5757;
	--color-green: #2fc07d;
	--color-grey: #98ACBA;
	--chart-color-orange: #e79f34;
	--chart-color-purple: #9e5ac3;
	--chart-color-blue: #507ee3;
	--chart-color-light-blue: #58ccfd;
	--chart-color-pink: #e377ab;
	--chart-color-dark-green: #13808f;
	--chart-color-olive-green: #b7c84f;
	--linear-gradient: linear-gradient(180deg, #507ee3 22.59%, #2fc07d 77.54%);
	--shadow-box: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.05);
	--calendar-shadow-box: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);
	--dropdown-hover-background-color: #f5f7f9;
	--border-radius: 0.4rem;
}

.light-theme {
	--primary-background-color: #fff;
	--secondary-background-color: #edf1f4;
	--primary-font-color: #11243b;
	--secondary-font-color: #626262;
	--tertiary-font-color: #4f4f4f;
	--black-font-color: #000;
	--navigation-icon-background: #cfdce5;
	--dropdown-background-color: #f4f7f9;
	--dropdown-selected-background-color: #fff;
	--location-menu-font-color: #11243bbf;
	--selected-font-color: #11243b80;
	--color-light-grey: #CED9E1;
	--alerts-button-background: #cfdce5;
}

.dark-theme {
	--primary-background-color: #344355;
	--secondary-background-color: #11243B;
	--primary-font-color: #FFFFFF;
	--secondary-font-color: #A9A9A9;
	--tertiary-font-color: #A9A9A9;
	--black-font-color: #FFFFFF;
	--navigation-icon-background: #344355;
	--dropdown-background-color: #526172;
	--dropdown-selected-background-color: #526172;
	--location-menu-font-color: #edf1f4;
	--selected-font-color: rgba(255, 255, 255, 0.4);
	--color-light-grey: #11243B;
	--alerts-button-background: #11243B;
}

.app {
	display: flex;
	width: 100%;
	height: 100vh;
	padding-top: env(safe-area-inset-top);
	padding-bottom: env(safe-area-inset-top);
	background: #000;
	overflow: hidden;
	font-family: "Nunito", sans-serif;

	button, input, optgroup, select, textarea {
		font-family: "Nunito", sans-serif;
	}

	&.isMobile,
	&.isSmallMobile {
		flex-direction: column;
	}

	/*** CALENDAR STYLES ***/
	.react-datepicker {
		width: 100%;
		background-color: transparent;
		border: none;
		font-family: "Nunito", sans-serif;

		.react-datepicker__navigation-icon::before {
			width: 0.7rem;
			height: 0.7rem;
			border-color: var(--primary-font-color);
			border-width: 0.2rem 0.2rem 0 0;
		}

		.react-datepicker__month-container {
			width: 100%;

			.react-datepicker__header {
				background-color: transparent;
				border: none;
				letter-spacing: 0.05rem;

				.react-datepicker__current-month {
					margin-bottom: 1rem;
					padding-bottom: 1.5rem;
					border-bottom: 0.1rem solid var(--navigation-icon-background);
					color: var(--secondary-font-color);
					font-weight: 700;
					font-size: 1rem;
					text-transform: uppercase;
				}

				.react-datepicker__day-names {
					margin: 0;

					.react-datepicker__day-name {
						margin: 0;
						width: 3.2rem;
						color: var(--secondary-font-color);
						font-size: 0.8rem;
						font-weight: 700;
						text-transform: uppercase;
					}
				}
			}

			.react-datepicker__month {
				.react-datepicker__day {
					margin: 0;
					padding: 0.5rem 0;
					width: 3.2rem;
					color: var(--primary-font-color);
					font-size: 1.4rem;
					font-weight: 700;

					&.react-datepicker__day--selected {
						color: var(--primary-background-color);
						background-color: var(--chart-color-blue);
					}
				}

				.react-datepicker__day--outside-month {
					color: #11243b40;
				}

				.react-datepicker__day--disabled {
					color: var(--color-red);
				}
			}
		}
	}

	/*** CHART TOOLTIP STYLES ***/
	.apexcharts-tooltip.apexcharts-theme-light {
		border: none;
	}

	.chart-tooltip {
		padding: 1rem;
		background-color: var(--primary-font-color);
		color: var(--secondary-background-color);
		font-size: 1.2rem;

		.tooltip-value {
			font-weight: 700;

			.tooltip-value-color {
				display: inline-block;
				width: 0.6rem;
				height: 0.6rem;
				border-radius: 100%;
			}
		}


	}
}
