.button {
  background: var(--color-green);
  border-radius: var(--border-radius);
  width: 181px;
  height: 40px;
  border: none;
  margin-bottom: 8px;
  position: absolute;
  top: 0;
  right: 0;
  color: var(--white-font-color);
  font-size: 1.6rem;
  align-items: center;
  cursor: pointer;
}

:global(.isDesktop) {
  .button {
    display: flex;
    margin-bottom: 0;
    justify-content: center;
  }
}